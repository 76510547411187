import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import htmr from 'htmr'

import { Section, Container } from '../components/Common'
import { PageHeader } from '../components/Common'
import { Statistics } from '../components/Statistics/'
import BookBanner from '../components/BookBanner/BookBanner'
import { useMeetTheTeam } from '../GraphQL/Queries'

const MeetTheTeam = () => {
    const data = useMeetTheTeam()
    const { acf_bookCTA, acf_meetTheTeam, title } = data

    const meetTheTeamContent = {
        title: acf_meetTheTeam.mainTitle,
        description: acf_meetTheTeam.mainDescription,
    }

    const book = {
        title: acf_bookCTA.title,
        image: acf_bookCTA.image.sourceUrlSharp.childImageSharp.fluid,
        alt: acf_bookCTA.image.altText,
    }

    return (
        <Layout noHero>
            <SEO title={title} />
            <PageHeader
                title={htmr(meetTheTeamContent.title)}
                description={htmr(meetTheTeamContent.description)}
            />
            <Statistics />

            <Section>
                <Container compact>
                    <div className="meet-team">
                        {acf_meetTheTeam.about.map((block, index) => {
                            return (
                                <div className="meet-team__block">
                                    <div className="meet-team__block-title">
                                        {htmr(block.bbtitle)}
                                    </div>
                                    <h2 className="meet-team__block-headline">
                                        {htmr(block.title)}
                                    </h2>
                                    <div className="meet-team__block-content">
                                        <p className="paragraph paragraph--lg paragraph--dark">
                                            {htmr(block.description)}
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </Container>
            </Section>
            <BookBanner image={book.image} alt={book.alt} title={book.title} />
        </Layout>
    )
}

export default MeetTheTeam

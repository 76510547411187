import React from 'react'
import { Section } from '../Common'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import htmr from 'htmr'

const BookBanner = ({ image, alt, title }) => {
    return (
        <Section>
            <div className="book-section">
                <div className="book-section__image-container">
                    <Img
                        fluid={image}
                        alt={alt}
                        className="book-section__image"
                    />
                </div>
                <div className="book-section__info">
                    <h1 className="book-section__info-title">{htmr(title)}</h1>
                    <Link
                        to="/book-consultation"
                        className="book-section__info-button"
                    >
                        BOOK NOW{' '}
                        <i className="icon icon--white icon--arrow-right" />
                    </Link>
                </div>
            </div>
        </Section>
    )
}

export default BookBanner

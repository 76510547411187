import React from 'react'
import { Section, Container } from '../Common'
import StatisticsCard from './StatisticsCard'

const Statistics = () => {
    return (
        <Section>
            <Container compact>
                <div className="statistics">
                    <div className="statistics__cards">
                        <StatisticsCard
                            attribute={'1H'}
                            title={'Average<br /> Response Time'}
                        />
                        <div className="statistics__divider"></div>
                        <StatisticsCard
                            attribute={'500<span>+</span>'}
                            title={'Satisfied<br />Clients'}
                        />
                        <div className="statistics__divider"></div>
                        <StatisticsCard
                            attribute={'100 <span>%</span>'}
                            title={'System Monitoring<br />Services Uptime'}
                        />
                    </div>
                </div>
            </Container>
        </Section>
    )
}

export { Statistics }

import React from 'react'
import htmr from 'htmr'

const StatisticsCard = ({ attribute, title }) => {
    return (
        <div className="statistics__card">
            <span className="statistics__card-number">{htmr(attribute)}</span>
            <span className="statistics__card-description">{htmr(title)}</span>
            <div className="divider divider--md divider--thick divider--blue"></div>
        </div>
    )
}

export default StatisticsCard
